var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{attrs:{"id":"footer-top"}},[_c('h3',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
        delay: 100,
        scale: 0,
        distance: '0px',
        opacity: null,
        duration: 1500}),expression:"{\n        delay: 100,\n        scale: 0,\n        distance: '0px',\n        opacity: null,\n        duration: 1500}"}]},[_vm._v("Thanks!")]),_c('div',{attrs:{"id":"footer-table"}},[_vm._m(0),_c('div',{attrs:{"id":"footer-wrapper-center"}},[_vm._m(1),_c('TheLike')],1),_c('div',{attrs:{"id":"footer-wrapper-right"}},[_c('TheScrollTop')],1)])]),_c('div',{attrs:{"id":"footer-bottom"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/impressum","rel":"”nofollow”","title":"Impressum"}},[_vm._v("Impressum")])],1),_c('li',[_c('router-link',{attrs:{"to":"/datenschutz","rel":"”nofollow”","title":"Datenschutz"}},[_vm._v("Datenschutz")])],1)]),_c('span',[_vm._v("Copyright © "+_vm._s(new Date().getFullYear())+" florianGlaeser")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer-wrapper-left"}},[_c('label',{attrs:{"for":"mail"}},[_vm._v("E-Mail")]),_c('a',{attrs:{"id":"mail","href":"mailto:kontakt@florianGlaeser.de","title":"E-Mail"}},[_vm._v("kontakt@florianGlaeser.de")]),_c('label',{attrs:{"for":"tel"}},[_vm._v("Phone")]),_c('a',{attrs:{"href":"tel:015223589422","id":"tel","title":"Telefon"}},[_vm._v("01522 35 89 4 22")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{staticClass:"icon-wrapper",attrs:{"href":"https://github.com/florianGlaeser","title":"GitHub","target":"_blank"}},[_c('i',{staticClass:"icon-github"})])]),_c('li',[_c('a',{staticClass:"icon-wrapper",attrs:{"href":"https://www.linkedin.com/in/florianGlaeser","title":"LinkedIn","target":"_blank"}},[_c('i',{staticClass:"icon-linkedin"})])]),_c('li',[_c('a',{staticClass:"icon-wrapper",attrs:{"href":"https://www.xing.com/profile/florian_Glaeser13","title":"Xing","target":"_blank"}},[_c('i',{staticClass:"icon-xing"})])])])
}]

export { render, staticRenderFns }