var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('TheHeader'),_c('TheMenu'),_c('div',{staticClass:"wapper-page"},[_c('section',{staticClass:"container-page",attrs:{"id":"about"}},[_c('article',{staticClass:"my"},[_vm._m(0),_c('Picture',{staticClass:"spacing",attrs:{"src":'Florian_Glaeser.jpg',"alt":'Ein Porträt von Florian Gläser',"title":'Portree von Florian Gläser'}})],1),_c('article',[_vm._m(1),_c('Picture',{staticClass:"spacing",attrs:{"src":'beam.jpg',"alt":'Backflip Animation von Florian Gläser',"title":'Backflip Animation Florian Gläser',"animation":true}}),_c('aside',{staticClass:"balancing"},[_c('h3',[_vm._v("Verletzungsrisiko")]),_vm._m(2),_c('TheCounter',{attrs:{"date":'2014-08-14'}})],1)],1)])]),_c('section',{staticClass:"wapper-page",attrs:{"id":"skills"}},[_c('div',{staticClass:"container-page"},[_vm._m(3),_c('article',{staticClass:"wrapper-skills"},[_vm._m(4),_c('div',{staticClass:"line",class:[_vm.isVisible ? 'active' : '']},[_c('h3',[_vm._v("Frontend")]),_vm._m(5)]),_vm._m(6)])])]),_c('section',{staticClass:"wapper-page",attrs:{"id":"work"}},[_c('div',{staticClass:"container-page"},[_vm._m(7),_c('article',[_c('div',{staticClass:"reference gap"},[_c('h3',[_vm._v("Entstehung und Gedankengang des Portfolios")]),_c('p',[_vm._v(" In diesem Bereich möchte ich auf das Portfolio selbst als Projekt eingehen. Ich möchte einen Teil meines Wissensstands veranschaulichen, damit Du es einfacher hast, mich einzuschätzen. ")]),_c('router-link',{attrs:{"to":"/reference/#explanation","title":"Referenz"}},[_vm._v("Mehr erfahren ...")])],1)])])]),_c('section',{attrs:{"id":"contact"}},[_vm._m(8),_c('article',[_c('TheContact',{staticClass:"spacing"})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info gap"},[_c('h2',[_vm._v("Über mich")]),_c('p',[_c('span',[_vm._v(" Mein Name ist Florian Gläser. ")]),_c('span',[_vm._v(" Ich bin Frondend Webentwickler aus Leidenschaft im Leipziger Raum. ")]),_c('span',[_vm._v(" Zuletzt habe ich mich mit dem Aufbau generischer UI Komponenten und der Entwicklung von Apps sowie progressiver Webapplikationen beschäftigt. ")]),_c('span',[_vm._v(" Hauptsächlich arbeitete ich mit Vue.js in Kombination aus Nuxt.js und Tailwind CSS, da sich mit diesen Tools moderne Webseiten effizient und performant umsetzen lassen. ")]),_c('span',[_vm._v(" Meine Kernkompetenzen liegen in der Teamarbeit und Kommunikation, semantischem Coding und im Allgemeinen das Produkt als Ganzes im Blick zu behalten. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"balancing gap"},[_c('h2',[_vm._v("Mein Ausgleich")]),_c('p',[_c('span',[_vm._v(" In meiner Freizeit bin ich sportlich sehr aktiv und betreibe Parkour/Freerunning, was mir die Möglichkeit bietet, meinen Ausgleich zu finden. ")]),_c('span',[_vm._v(" Im Zuge dessen betreue ich seit 2013 verschiedene Projekte und Prozesse für Kinder und Jugendliche, um ihnen einen sicheren Raum für den Sport zu bieten. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" Da in diesem Zusammenhang die Frage nach dem Verletzungsrisiko sehr häufig gestellt wird, möchte ich diese mit einem Unfallfreicounter beantworten. ")]),_c('span',[_vm._v("Unverletzt seit:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Fähigkeiten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_c('p',[_vm._v("Software")]),_c('p',[_vm._v("Development")])]),_c('ul',[_c('li',[_vm._v("Erfahrung in funktionaler und OOP Entwicklung")]),_c('li',[_vm._v("JavaScript und TypeScript Programmierspche")]),_c('li',[_vm._v("Entwicklungsalltag mit Bitbucket, Firebase, Docker, GitLab, GitHub, Jira/ Confluence und Scrum-Team")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Leidenschaft für UI/UX")]),_c('li',[_vm._v("Über 3 Jahre Entwicklungserfahrung in HTML, CSS, Tailwind CSS, Vue.js und Nuxt.js Frameworks")]),_c('li',[_vm._v("Komplexe und performante Animationen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Mobile")]),_c('ul',[_c('li',[_vm._v("Erfahrung in der Entwicklung progressiver Webapplikationen und plattformübergreifender Lösungen")]),_c('li',[_vm._v("Verwendung des Ionic frameworks mit Android Studio und Xcode")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Referenz")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Kontakt")])])
}]

export { render, staticRenderFns }