var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"counter"},_vm._l((_vm.dateTag),function(tag,index){return _c('div',{key:index,staticClass:"dateLabel"},[_c('label',{attrs:{"for":tag}},[_vm._v(_vm._s(tag))]),_c('div',{staticClass:"counterNumbers",attrs:{"id":tag}},_vm._l((_vm.dateNumbers[index]),function(dateNumber,x){return _c('CounterNumber',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
          origin: 'top',
          delay: index*800,
          distance: '0px',
          rotate: {x: dateNumber*36},
          opacity: null,
          duration: 2000}),expression:"{\n          origin: 'top',\n          delay: index*800,\n          distance: '0px',\n          rotate: {x: dateNumber*36},\n          opacity: null,\n          duration: 2000}"}],key:x,attrs:{"number":parseInt(dateNumber)}})}),1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }